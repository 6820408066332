<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Concluindo criação do evento</h4>
            <div class="row">
              <div class="col-lg-12">
                <div class="text-center pb-4">
                  <div class="row justify-content-center mt-1">
                    <div class="col-sm-4">
                      <div class="maintenance-img">
                        <img
                          src="@/assets/images/coming-soon.svg"
                          alt=""
                          width="50%"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                  <h4 class="mt-2">{{ event_step.message }}</h4>
                  <p class="text-muted" v-if="event_step.percent < 100">{{ event_step.info }}</p>
                  <div class="progress" style="height: 35px;" v-if="event_step.percent < 100">
                    <div
                        class="progress-bar progress-bar-striped progress-bar-animated"
                        role="progressbar"
                        v-bind:style="{ width: event_step.percent + '%'}"
                        v-bind:aria-valuenow="event_step.percent"
                        :value="event_step.percent"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        >{{ event_step.percent }}%</div>
                  </div>
                  <p class="text-muted mt-5" v-if="event_step.percent < 100">{{ event_step.infoOut }}</p>
                  <div class="mt-5 text-center" v-if="event_step.percent === 100">
                    <button class="btn btn-primary me-2" @click="accessEvent(event.url_admin)">Gerênciar Evento</button>
                    <button class="btn btn-primary me-2" @click="accessEvent(event.url_event)">Plataforma Evento</button>
                    <router-link class="btn btn-dark" to="/events">Meus Eventos</router-link>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Layout,
    PageHeader
  },
  name: 'form-event',
  page: {
    title: 'Novo Evento',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      step: 4,
      message_check_domain: '',
      event_step: {
        message: 'Aguarde, estamos criando o seu evento...',
        info: 'Leva menos de 5 minutos ;)',
        infoOut: 'Fique tranquilo, avisaremos você via e-mail assim que o processo finalizar.',
        percent: 0
      },
      plans: [],
      event: {
        id: '',
        name: '',
        status: 0,
        description: '',
        user: {
          password: '',
          name: '',
          email: ''
        }
      },
      domain: {
        type: '',
        name: '',
        available: false
      },
      submitted: false,
      title: 'Criando Evento',
      items: [{
        text: 'Meus Eventos',
        href: '/events'
      },
      {
        text: 'Em Produção',
        active: true
      }
      ]
    }
  },
  mounted () {
    this.loadItem()
  },
  beforeMount () {
    this.connectSocket()
  },
  computed: {
    ...mapState('events', { data: 'event' }),
    ...mapState('auth', ['user'])
  },
  methods: {
    ...mapActions('events', {
      fetch: 'getEvent',
      checkDNS: 'checkDomainDNS',
      postData: 'save'
    }),
    connectSocket () {
      this.$pusher.subscribe(`event-steps.${this.user.company_id}`).bind('EventSteps', (response) => {
        if (response) {
          this.event_step.percent = response.percent
          this.event_step.message = response.message
        }
      })
    },
    accessEvent (url) {
      window.open(url, '_blank')
    },
    loadItem () {
      if (this.$route.params.id !== undefined) {
        let loader = this.$loading.show()
        this.fetch(this.$route.params.id)
          .then(() => {
            this.event = this.data
            if (this.event.status === 1) {
              this.event_step.message = 'Tudo pronto! Evento criado com sucesso!'
            }
            this.event.start_date = this.$moment(this.event.start_date, 'YYYY-MM-DD HH:mm').toDate()
            this.event.end_date = this.$moment(this.event.end_date, 'YYYY-MM-DD HH:mm').toDate()
            if (this.data.users) {
              this.event.user = this.data.users.data
            }
          }).then(() => {
            this.event_step.percent = this.event.percent
            loader.hide()
          })
      }
      let loader = this.$loading.show()
      loader.hide()
    }
  },
  watch: {
    'domain.name' (value) {
      this.domain.name = value.replace(/[^a-zA-Z0-9]/g, '')
    }
  }
}
</script>

<style scoped>
  .domain-eventfy {
    padding: 20px;
    gap: 1rem;
    text-align: center;
    padding-top: 15%;
    max-height: 35rem;
    height: 23rem;
    background: #FFFFFF;
    border: 1px solid #45506F;
    box-sizing: border-box;
    box-shadow: 0px 3px 8px 4px rgba(0, 0, 0, 0.01);
    border-radius: 7px;
    margin: 2rem;
    cursor: pointer;
  }
  .domain-eventfy:hover {
    background: #eaefff;
  }
  .active {
    background: #eaefff;
  }
</style>
